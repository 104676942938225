$border: #2D3047;
@mixin size($w: 10%, $h: 10%) {
  width: $w;
  height: $h;
}

body {
  overflow: hidden;
//   background: #E0AC9D;
}

.container {
  top:50px;
  margin-right:40px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
//   height: 100vh;
}

.headphones {
  position: relative;
}

.head {
  @include size(15vmin, 15vmin);
  border-radius: 100%;
  border: 1.5vmin solid;
  border-color: $border $border transparent transparent;
  transform: rotate(-45deg);
}

.ear {
  position: absolute;
  @include size(2.5vmin, 7.5vmin);
  top: 8.25vmin;
  left: -5vmin;
  border-radius: 100%;
  border-left: 5vmin solid #F27059;
  &:before,
  &:after {
    position: absolute;
    content: '';
  }
  &:before {
    @include size(2.5vmin, 7.5vmin);
    top: 0vmin;
    left: 15.7vmin;
    border-radius: 100%;
    border-right: 5vmin solid #F27059;
  }
  &:after {
    @include size(1.5vmin, 7.5vmin);
    background: $border;
    border-radius: 100px;
    box-shadow: 16.6vmin 0 0 $border;
  }
}