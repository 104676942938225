$color-env          : #0077B2;
$color-env2         : darken($color-env, 3%);
$color-flap         : darken($color-env, 20%);
$color-bg           : lighten($color-env, 48%);
$color-heart        : #D00000;

$env-border-radius  : 6px;
$env-width          : 280px;
$env-height         : 180px;
$heart-width        : 50px;

#envelope {
    position: relative;
    width: $env-width;
    height: $env-height;
    border-bottom-left-radius: $env-border-radius;
    border-bottom-right-radius: $env-border-radius;
    margin-left: auto;
    margin-right: auto;
    top: 150px;
    background-color: $color-flap;
    box-shadow: 0 4px 20px rgba(0,0,0,.2);
}
.front {
    position: absolute;
    width: 0;
    height: 0;
    z-index: 3;
}
.flap {
    border-left: ($env-width / 2) solid transparent;
    border-right: ($env-width / 2) solid transparent;
    border-bottom: ($env-height / 2) - 8 solid transparent; /* a little smaller */
    border-top: ($env-height / 2) + 8 solid $color-flap; /* a little larger */
    transform-origin: top;
    pointer-events: none;
}
.pocket {
    border-left: ($env-width / 2) solid $color-env;
    border-right: ($env-width / 2) solid $color-env;
    border-bottom: ($env-height / 2) solid $color-env2;
    border-top: ($env-height / 2) solid transparent;
    border-bottom-left-radius: $env-border-radius;
    border-bottom-right-radius: $env-border-radius;
}
.letter {
    position: relative;
    background-color: #fff;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: 90%;
    top: 5%;
    border-radius: $env-border-radius;
    box-shadow: 0 2px 26px rgba(0,0,0,.12);
}
.letter:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image:
        linear-gradient(180deg,
        rgba(255,255,255,0.00) 25%,
        rgba(215,227,239,0.70) 55%,
        rgba(215,227,239,1.00) 100%);
}
.words {
    position: absolute;
    left: 10%;
    width: 80%;
    height: 14%;
    background-color: #EEEFF0;
}
.words.line1 {
    top: 15%;
    width: 20%;
    height: 7%;
}
.words.line2 {
    top: 30%;
}
.words.line3 {
    top: 50%;
}
.words.line4 {
    top: 70%;
}
.open .flap {
    transform: rotateX(180deg);
    transition: transform .4s ease, z-index .6s;
    z-index: 1;
}
.close .flap {
    transform: rotateX(0deg);
    transition: transform .4s .6s ease, z-index 1s;
    z-index: 5;
}
.close .letter {
    transform: translateY(0px);
    transition: transform .4s ease, z-index 1s;
    z-index: 1;
}
.open .letter {
    transform: translateY(-($env-height / 3));
    transition: transform .4s .6s ease, z-index .6s;
    z-index: 2;
}
.hearts {
    position: absolute;
    top: ($env-height / 2);
    left: 0;
    right: 0;
    z-index: 2;
}
.heart {
    position: absolute;
    bottom: 0;
    right: 10%;
    pointer-events: none;
}

.heart:before,
.heart:after {
    position: absolute;
    content: "";
    left: $heart-width;
    top: 0;
    width: $heart-width;
    height: ($heart-width * 1.6);
    background: $color-heart;
    border-radius: $heart-width $heart-width 0 0;
    transform: rotate(-45deg);
    transform-origin: 0 100%;
    pointer-events: none;
}
.heart:after {
    left: 0;
    transform: rotate(45deg);
    transform-origin: 100% 100%;
}
.close .heart {
    opacity: 0;
    animation: none;
}
.a1 {
    left: 20%;
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    -webkit-animation:
        slideUp 4s linear 1,
        sideSway 2s ease-in-out 4 alternate;
    -moz-animation:
        slideUp 4s linear 1,
        sideSway 2s ease-in-out 4 alternate;
    animation-fill-mode: forwards;
    animation-delay: .7s;
}
.a2 {
    left: 55%;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -webkit-animation:
        slideUp 5s linear 1,
        sideSway 4s ease-in-out 2 alternate;
    -moz-animation:
        slideUp 5s linear 1,
        sideSway 4s ease-in-out 2 alternate;
    animation-fill-mode: forwards;
    animation-delay: .7s;
}
.a3 {
    left: 10%;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 1;
    -webkit-animation:
        slideUp 7s linear 1,
        sideSway 2s ease-in-out 6 alternate;
    -moz-animation:
        slideUp 7s linear 1,
        sideSway 2s ease-in-out 6 alternate;
    animation-fill-mode: forwards;
    animation-delay: .7s;
}

@-webkit-keyframes slideUp {
    0% {
        top: 0;
    }
    100% {
        top: -600px;
    }
}
@keyframes slideUp {
    0% {
        top: 0;
    }
    100% {
        top: -600px;
    }
}
@-webkit-keyframes sideSway {
    0% {
        margin-left: 0px;
    }
    100% {
        margin-left: 50px;
    }
}
@keyframes sideSway {
    0% {
        margin-left: 0px;
    }
    100% {
        margin-left: 50px;
    }
}

body {
    background-color: $color-bg;
}
.envlope-wrapper {
    height: ($env-height + 200px);
}
.reset {
    text-align: center;
}
.reset button {
    font-weight: 800;
    font-style: normal;
    transition: all .1s linear;
    -webkit-appearance: none;
    background-color: transparent;
    border: solid 2px $color-env;
    border-radius: 4px;
    color: $color-env;
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
    margin: 5px;
    padding: 10px;
    line-height: 1em;
    text-decoration: none;
    min-width: 120px;
    cursor: pointer;
}
.reset button:hover {
    background-color: $color-env;
    color: #fff;
}