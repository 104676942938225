$color-env          : #0077B2;
$color-env2         : darken($color-env, 3%);
$color-flap         : darken($color-env, 20%);
$color-bg           : lighten($color-env, 48%);
$color-heart        : #D00000;

$env-border-radius  : 6px;
$env-width          : 280px;
$env-height         : 180px;
$heart-width        : 40px;

.letterHeartContainer {
    top:70px;
    position: relative;
}

.letterOverlay {
    font-family: 'Emilys Candy', cursive;
    width:80%;
    max-width:400px;
    height:80vh;
    max-height:600px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 6px;
    box-shadow: 0 2px 26px rgba(0, 0, 0, 0.12);
    transition: opacity 1s ease-out;
    opacity: 0;
    padding:10px;
    font-size:22px;
}

.displayLetter {
    opacity: 1;
    z-index: 10;
}

.letterContainer {
    position: absolute;
    top:0;
    display:flex;
    align-items: center;
    justify-content: center;
    width:100vw;
    height:100vh;
}

.letterHeart {
    pointer-events: none;
}

.letterHeart:before,
.letterHeart:after {
    position: absolute;
    content: "";
    left: $heart-width;
    top: 0;
    width: $heart-width;
    height: ($heart-width * 1.6);
    background: $color-heart;
    border-radius: $heart-width $heart-width 0 0;
    transform: rotate(-45deg);
    transform-origin: 0 100%;
    pointer-events: none;
}
.letterHeart:after {
    left: 0;
    transform: rotate(45deg);
    transform-origin: 100% 100%;
}